<template>
<div class="home">
  <h1 class="tagline">Make Your Products Truly Unique...</h1>
  <ul>
<li>Each tag encoded with its own individual Non-Fungible Token</li>
    </ul>
  <div class="product-boxes">
<div class="product-box"
    v-for='car in cars'
    :key='car.id'  
  >
  <img :src="getImgUrl(car.url)"/>
    {{ car.brand }}
  </div>
    </div>
 
   <!-- <HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/>-->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
   // Data property
  cars = [
    {
      id: 112333,
  brand: 'Durable NFT',
  url: 'ButtonNFC.jpg'

  }, {
    id: 112443,
    brand: 'mercedes',
    url: 'ButtonNFC.jpg'
  },
  {
    id: 1123533,
    brand: 'fiat',
    url: 'ButtonNFC.jpg'
  },
  {
    id: 912333,
    brand: 'fiat',
    url: 'ButtonNFC.jpg'
  },
  {
    id: 199933,
    brand: 'fiat',
    url: 'ButtonNFC.jpg'
  }
  ]

  getImgUrl(pic: string): () => string {
    return require('../assets/'+ pic)
}
}
</script>
<style scoped lang="scss">

@font-face {
  font-family: "Open Sans Semi Bold";
  src: local("Open Sans Semi"),
   url(../fonts/Open_Sans/OpenSans-SemiBold.ttf) format("truetype");
}

.tagline {
  color: #EBEBEB;
  font-family: "Open Sans Semi Bold";
  font-size: 50px;
  margin: 50px;
}
.product-boxes {
display: flex;
  flex-wrap: wrap;
   justify-content: space-around;
}
.product-box {
 display: flex;
 flex-direction: column;
 align-items: center;
  width: 10vw;
  border: 0.5px solid #F7DEEB;
  background-color: white;
  border-radius: 20px;
  
}
img {
  width: 100%;
border-radius: inherit;
  
}
.home {
   text-align: center;
 background-color: #02111B;
   opacity:0.95;
   padding:20px;
 height: 100vh;
  
  
   

}


</style>
