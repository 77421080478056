<template>

<header>
  <div class="top-header">
    <div class="placeholder">Sign Up Sign In</div>
  <router-link to="/">
   <div class="title">
      

     <h1>Real World <span>NFT</span></h1>
     <svg width="100%" height="100%" version="1.1" viewBox="0 0 27.023 32.012" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
 <path fill="#5D737E" class="clr-i-solid clr-i-solid-path-1" d="m13.512 0.011512c-7.2-0.3-13.2 5.3-13.5 12.5-0.3 7.2 5.3 13.2 12.5 13.5v-5.2l-5.2-5.2c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.4 0l3.8 3.8v-6.2l-2.7-2.7c-0.4-0.4-0.4-1 0-1.4 0.4-0.4 1-0.4 1.4 0l3.3 3.3v3l3.3-3.3c0.4-0.4 1-0.4 1.4 0s0.4 1 0 1.4l-4.7 4.7v9.2c7.2-0.3 12.8-6.3 12.5-13.5-0.3-7.2-6.3-12.8-13.5-12.5z"/>
 <!--<path class="clr-i-solid clr-i-solid-path-2" d="m13.512 26.012h-1v5c0 0.6 0.4 1 1 1s1-0.4 1-1v-5h-1z"/>
 <rect x="-4.4885" y="-1.9885" width="36" height="36" fill-opacity="0"/>-->
 <g fill="#fff" stroke="#000" stroke-width="0">
  <ellipse cx="5.8416" cy="12.864" rx="2.4939" ry="2.5382"/>
  <ellipse cx="20.561" cy="9.3169" rx="2.4939" ry="2.5382"/>
  <ellipse cx="8.546" cy="6.1248" rx="2.4939" ry="2.5382"/>
 </g>
</svg>
     </div>
</router-link>
<div>Sign In Sign Up</div>
</div>

   <div class="nav">
    
    
    <router-link to="/products">Why?</router-link>
     <router-link to="/about">How?</router-link> 
    <router-link to="/products">Products</router-link>
   
      <router-link to="/about">Your Account</router-link>    
    <router-link to="/about">In Depth...</router-link>
   
  </div>
</header>
<!--<hr class="solid"/>-->
<router-view/>
</template>

<style lang="scss">

@font-face {
  font-family: "Zekton";
  src: local("Zekton"),
   url(./fonts/zekton.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Light";
  src: local("Open Sans Light"),
   url(./fonts/Open_Sans/OpenSans-Light.ttf) format("truetype");
}

/*hr.solid {
  border-top: 0.5px solid #02111B;
  opacity: 0.8;
}*/

#app {
 font-family: Zekton;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  //color: $primaryText;
  background-color: #02111B;
  
  
}

.top-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .placeholder {
    visibility: hidden;
  }
}
// https://coolors.co/02111b-3f4045-30292f-5d737e-fcfcfc
header {
 // background-image: linear-gradient(to right, #7EC9C4 5%, white);
  background-color:#02111B;
  opacity: 0.95;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.title {
  font-family: Zekton;
  opacity: 0.9;
  color: #5D737E;
  background-color:#02111B;
  display: flex;
  // align-items: center;
  //box-shadow:  -1px -1px 3px 0 #5D737E,4px 4px 6px 0 black;
 // box-shadow: 
 //   3px 3px 4px 0 #5D737E,
 //   -3px -3px 4px 0 #5D737E;
//  border-radius:5% 95% 12% 88% / 81% 12% 88% 19%;
 margin: 15px;
  font-size: 20px;
 // margin-left: 15px;
 //  padding: 15px;
  h1{
   
  padding: 11px;
   &>span {
   color: #EBEBEB;
   letter-spacing: 1px;
  }
 }
 svg {
   width: 45px;
   opacity: 0.9;
 }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 15px;
svg {
    width: 40px;
  }
  }
  
}

.nav {
 padding: 5px;
 background-color: #042439;
 font-family: "Open Sans Light";
 // width: 100%;
 border-radius: 20px;
 display: flex;
 justify-content: center;

  a {
    font-weight: bold;
    color: #EBEBEB;
    opacity: 0.6;
    padding: 5px;
    font-size: 20px;
    margin: 0 30px 0 30px;

    &.router-link-exact-active {
      color: #5D737E;
      opacity: 0.7;
    }
  }
}

</style>
