// RESET CSS
import './assets/reset.css';

// DEPENDENCIES
import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import 'primeflex/primeflex.css';
import './registerServiceWorker'
import router from './router'
import store from './store'

// IMPORT PRIME VUE COMPONENTS
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Carousel from 'primevue/carousel';
import Button from 'primevue/button';

// REGISTER APP
const app = createApp(App);

// ADD PRIME VUE, ROUTER AND THEN MOUNT
app.use(store).use(PrimeVue).use(router).mount('#app');

// REGISTER PRIME VUE COMPONENTS
app.component('Dialog', Dialog);
app.component('Carousel', Carousel);
app.component('Divider', Divider);
app.component('Button', Button);