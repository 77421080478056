
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
   // Data property
  cars = [
    {
      id: 112333,
  brand: 'Durable NFT',
  url: 'ButtonNFC.jpg'

  }, {
    id: 112443,
    brand: 'mercedes',
    url: 'ButtonNFC.jpg'
  },
  {
    id: 1123533,
    brand: 'fiat',
    url: 'ButtonNFC.jpg'
  },
  {
    id: 912333,
    brand: 'fiat',
    url: 'ButtonNFC.jpg'
  },
  {
    id: 199933,
    brand: 'fiat',
    url: 'ButtonNFC.jpg'
  }
  ]

  getImgUrl(pic: string): () => string {
    return require('../assets/'+ pic)
}
}
