<template>
  <div class="about">
    <div class="p-grid p-jc-center p-nogutter">
    <div class="banner p-col-11"><h2>Physical products, on the blockchain...</h2>
    <ul>
      <li>Every product attached to a unique Non-Fungible Token.</li>
      <li>Limited number of NFT's released for each product line.</li>
      <li>Option to transfer ownership of NFT to your crypto wallet.</li>
      </ul></div>
    <div class=" banner p-col-12">For a guide and recipe wo update on how to configure / customize this project,
check out the vue-cli documentation.</div>
    <div class="banner p-col-12">For a guide and recipe wo update on how to configure / customize this project,
check out the vue-cli documentation.</div>
  </div>
    <h1>This is an about page</h1>
  </div>
</template>
<style scoped lang="scss">
ul {
  list-style-type: square;
}
.banner {
  color: $primaryBackground;
  padding: 40px;
  margin: 25px;
  background-color: $primaryText;
  border-radius: 30% 70% 93% 7% / 13% 19% 81% 87%  ;
  li {
    padding: 10px;
  }
}
</style>